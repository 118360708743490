<template>
    <div class="form">
        <el-form ref="form" :model="sizeForm" label-width="80px" size="mini">
            <el-form-item label="活动区域">
                <el-select v-model="sizeForm.region" placeholder="请选择活动区域">
                    <el-option label="区域一" value="tab1"></el-option>
                    <el-option label="区域二" value="tab2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="活动名称">
                <el-input v-model="sizeForm.name" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item size="large">
                <el-button type="primary" @click="onSubmit">立即创建</el-button>
                <el-button>取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                sizeForm: {
                    name: '',
                    region: '',
                },
                isDisabled: false,
            }
        },
        watch:{
            'sizeForm.region'(val){
                val == 'tab1' ? this.isDisabled = true : this.isDisabled;
            }
        },
        methods:{
            onSubmit(sizeForm) {
                if(this.sizeForm.region == 'tab1'){
                    this.sizeForm.name = '';
                }else{
                    this.$refs[sizeForm].validate((valid) => {
                        if (valid) {
                            console.log(valid)
                        } else {
                            console.log('error submit!!');
                            return false;
                        }
                    });
                }
            }
        }
    }
</script>

<style>

</style>